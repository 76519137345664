

import { Options } from "vue-class-component";
import { Tab } from "bootstrap";
import PageBase from "@/shared/components/common/PageBase";

import ConfigurationImages from "./ConfigurationImages.vue";
import ConfigurationVehicles from "./ConfigurationVehicles.vue";
import { SignalR } from "@/support/SignalR";

@Options({
    components: {
        ConfigurationImages,
        ConfigurationVehicles,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, show a modal (if any)
                this.hideImageModal(true, false); // hide the current modal (if any)
                this.hideVehicleModal(true, false);
                if (to.query.imageid !== undefined) // if there is an image id, open the modal
                    this.showImageModal(Number(to.query.imageid));
                else if (to.query.vehicleid !== undefined) { // if there is a vehicle id, open the modal
                    this.showVehicleModal(Number(to.query.vehicleid), to.query.clone);
                } else if (to.query.tab === "images")
                    this.activateImagesTab();
                else if (to.query.tab === "vehicles")
                    this.activateVehiclesTab();
            }
        }
    }
})
export default class Configuration extends PageBase {

    showImageModal(imageId?: number): void {
        // always activate the "Images for Calibrations" tab
        this.activateImagesTab();
        this.$refs.configurationimages.show(imageId);
    }
    hideImageModal(forceReload?: boolean, setUrl?: boolean): void {
        this.$refs.configurationimages.hide(forceReload, setUrl);
    }

    showVehicleModal(vehicleId?: number, clone?: string): void {
        // always activate the "Configure Vehicles" tab
        this.activateVehiclesTab();
        this.$refs.configurationvehicles.show(vehicleId, clone);
    }
    hideVehicleModal(forceReload?: boolean, setUrl?: boolean): void {
        this.$refs.configurationvehicles.hide(forceReload, setUrl);
    }

    activateImagesTab(): void {
        const tab = new Tab(this.$refs.imagesCalibrationTab);
        tab.show();
    }
    activateVehiclesTab(): void {
        const tab = new Tab(this.$refs.configVehiclesTab);
        tab.show();
    }

    mounted(): void {
        SignalR.whenReady((): void => {
            this.pageLoading = false;
            const query = this.$router.currentRoute.value.query;
            const vehicleId = query.vehicleid;
            const imageId = query.imageid;
            if (vehicleId !== undefined)
                this.showVehicleModal(Number(vehicleId));
            else if (imageId !== undefined)
                this.showImageModal(Number(imageId));
        });
    }

    $refs!: {
        configurationimages: ConfigurationImages,
        configurationvehicles: ConfigurationVehicles,

        configVehiclesTab: HTMLElement,
        imagesCalibrationTab: HTMLElement,
    }
}

